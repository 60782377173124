import axios, { AxiosStatic } from "axios";
import { parseTimesheet, Timesheet } from "@momenta/common/timesheets";
import { makeRequest, PageResult } from "@momenta/common";

export class TimesheetApi {

    private readonly apiUrl: string = "/api/timesheet";

    private axios: AxiosStatic;

    constructor(ax: AxiosStatic) {
        this.axios = ax;
    }

    public async getAll(timesheetState: string = "Submitted"): Promise<Timesheet[]> {

        const filter = `State eq Momenta.Model.TimesheetState'${timesheetState}'`;
        const request = makeRequest(this.apiUrl, {
            filter
        });

        const response = await this.axios.get(request);
        const { items } = response.data as PageResult<Timesheet>;

        return items.map(parseTimesheet.bind(this));
    }

    public async get(id: number): Promise<Timesheet> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const timesheet = response.data as Timesheet;

        return parseTimesheet(timesheet);
    }

    public async save(model: Timesheet): Promise<Timesheet> {
        const response = await axios.put(`${this.apiUrl}/${model.id}`, model);
        const timesheet = response.data as Timesheet;

        return parseTimesheet(timesheet);
    }
}

const timesheetApi = new TimesheetApi(axios);

export {
    timesheetApi
};
