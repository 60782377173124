import * as actions from "./actionTypes";
import { InvoicePeriod } from "./model";
import { InvoicePeriodAction } from "./actions";

export function invoicePeriods(state: InvoicePeriod[] = [], action: InvoicePeriodAction): InvoicePeriod[] {
    switch (action.type) {
        case actions.LOAD_INVOICE_PERIODS_SUCCESS:
            return action.payload;
        case actions.LOAD_INVOICE_PERIOD_SUCCESS:
            if (state.some(t => t.id === action.payload.id)) {
                return state.map(t => invoicePeriod(t, action));
            }
            return [...state, action.payload];
        default:
            return state;
    }
}

function invoicePeriod(state: InvoicePeriod, action: InvoicePeriodAction): InvoicePeriod {
    switch (action.type) {
        case actions.LOAD_INVOICE_PERIOD_SUCCESS:
            if (state.id === action.payload.id) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
}
