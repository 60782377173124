import * as React from "react";

import { Grid, Table } from "semantic-ui-react";

import { Contract } from "../model";

import { ContractTableRow } from "./ContractTableRow";

interface ContractTableProps {
    contracts: Contract[];
}

export const ContractTable: React.FC<ContractTableProps> = ({ contracts }) => {
    return (
        <Grid.Row>
            <Grid.Column>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Momenta Associate ID</Table.HeaderCell>
                            <Table.HeaderCell>Associate Name</Table.HeaderCell>
                            <Table.HeaderCell>Client</Table.HeaderCell>
                            <Table.HeaderCell>Project</Table.HeaderCell>
                            <Table.HeaderCell>Role</Table.HeaderCell>
                            <Table.HeaderCell>Start Date</Table.HeaderCell>
                            <Table.HeaderCell>View</Table.HeaderCell>
                            <Table.HeaderCell>Contract Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {contracts.length === 0 && (
                            <Table.Row>
                                <Table.Cell content="No Contracts found" colSpan={8} textAlign="center" disabled />
                            </Table.Row>
                        )}
                        {contracts.map(contract => {
                            return (
                                <ContractTableRow
                                    key={contract.momentaAssociateID}
                                    contract={contract}
                                />
                            );
                        })}
                    </Table.Body>
                </Table>
            </Grid.Column>
        </Grid.Row>
    );
};
