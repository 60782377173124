import * as React from "react";
import moment from "moment";
import { Form, Input as SearchInput, Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { connect } from "react-redux";
import { push, State as RouterState } from "redux-little-router";
import { debounce } from "@neworbit/simpleui-utils";

interface InvoiceTableClearInvoicesToProcessProps {
    clearInvoicesToProcess: () => void;
}

interface InvoiceTableFilterProps {
    query: any;
    debounce?: number;
}

interface InvoiceTableFilterFunction {
    onInvoiceFilter: (filter: any) => void;
}

interface InvoiceTableDispatchProps {
    handleInvoiceFilterChange: (query: any, filter: any) => void;
}

export interface InvoiceTableFilterState {
    associateName: string;
    invoiceNumber: string;
    paymentDueDate: moment.Moment;
    processed: boolean;
}

export class InvoiceTableFilterUnconnected extends React.Component<
    InvoiceTableFilterProps & InvoiceTableDispatchProps & InvoiceTableFilterFunction & InvoiceTableClearInvoicesToProcessProps, InvoiceTableFilterState> {

    public state: InvoiceTableFilterState = {
        associateName: this.props.query.associateName || "",
        invoiceNumber: this.props.query.invoiceNumber || "",
        paymentDueDate: this.props.query.paymentDueDate || "",
        processed: this.props.query.processed === "true"

    };

    private onDateChangeDebounced = debounce((
        value: moment.Moment) => this.onDateChange(value),
    this.props.debounce || 1000
    );

    public render() {
        const { associateName, invoiceNumber, paymentDueDate, processed } = this.state;

        const associateNameAction = this.props.query.associateName && this.props.query.associateName.length && associateName === this.props.query.associateName
            ? { icon: "close", onClick: this.clearAssociateName }
            : { icon: "search", onClick: this.handleSubmit };

        const invoiceNumberAction = this.props.query.invoiceNumber && this.props.query.invoiceNumber.length && invoiceNumber === this.props.query.invoiceNumber
            ? { icon: "close", onClick: this.clearInvoiceNumber }
            : { icon: "search", onClick: this.handleSubmit };

        const invoiceFilter = (fieldName: any) => (event: any, data: any) => this.onInvoiceFilter(data.value, fieldName);

        return (
            <>
                <Grid.Column width={4}>
                    <Form onSubmit={this.handleSubmit} noValidate>
                        <Form.Field>
                            <label>
                                Search Associate Name
                            </label>
                            <SearchInput
                                value={associateName}
                                placeholder="Search associate name"
                                onChange={invoiceFilter("associateName")}
                                action={associateNameAction}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Form>
                        <Form.Field>
                            <label>
                                Search Payment Due Date
                            </label>
                            <Input.Date
                                value={moment(paymentDueDate)}
                                onChange={this.onDateChangeDebounced}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Form onSubmit={this.handleSubmit} noValidate>
                        <Form.Field>
                            <label>
                                Search Invoice Number
                            </label>
                            <SearchInput
                                value={invoiceNumber}
                                placeholder="Search invoice number"
                                onChange={invoiceFilter("invoiceNumber")}
                                action={invoiceNumberAction}
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Form noValidate>
                        <Form.Field>
                            <label>
                                Show Processed
                            </label>
                            <Input.Checkbox
                                value={processed}
                                onChange={this.onProcessedSearch}
                                toggle
                            />
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </>
        );
    }

    private onDateChange = (value: moment.Moment) => {
        if (value && value.isValid() === false) {
            return;
        }

        this.setState(previous => ({
            ...previous,
            paymentDueDate: value
        }));

        const query = value && value.isValid() ? value.format() : "";

        this.handleQuery(query, "paymentDueDate");
    }

    private onInvoiceFilter = (value: any, keyName: keyof InvoiceTableFilterState) => {

        this.setState(previous => ({
            ...previous,
            [keyName]: value
        }));

        if (value.length === 0) {
            this.handleQuery(value, keyName);
        }
    }

    private onProcessedSearch = (processed: boolean) => {

        this.setState(previous => ({
            ...previous,
            processed
        }));

        this.props.onInvoiceFilter(this.state);
    }

    private handleQuery = (value: any, keyName: keyof InvoiceTableFilterState) => {
        const query = { ...this.state, [keyName]: `${value}` };
        this.props.onInvoiceFilter(query);
    }

    private handleSubmit = () => {
        this.props.onInvoiceFilter(this.state);
    }

    private clearAssociateName = () => {
        this.setState({ associateName: "" }, () => this.props.onInvoiceFilter(this.state));
    }

    private clearInvoiceNumber = () => {
        this.setState({ invoiceNumber: "" }, () => this.props.onInvoiceFilter(this.state));
    }
}

const mapStateToProps = (state: RouterState): InvoiceTableFilterProps => ({
    query: state.router.query || {}
});

const mapDispatchToProps = (dispatch: any): InvoiceTableDispatchProps => ({
    handleInvoiceFilterChange: (query: any, newFilter: any) => {
        const filter = { ...newFilter, processed: newFilter.processed ? "true" : "false" };
        dispatch(push({ query: { ...query, ...filter, }, pathname: undefined }));
    }
});

const mergeProps = (propsFromState: InvoiceTableFilterProps, propsFromDispatch: InvoiceTableDispatchProps, ownProps: InvoiceTableClearInvoicesToProcessProps):
InvoiceTableFilterProps & InvoiceTableDispatchProps & InvoiceTableFilterFunction => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,
    onInvoiceFilter: (filter: any) => {
        propsFromDispatch.handleInvoiceFilterChange(propsFromState.query, filter);
        ownProps.clearInvoicesToProcess();
    },
});

export const InvoiceTableFilter = connect(mapStateToProps, mapDispatchToProps, mergeProps)(InvoiceTableFilterUnconnected);
