import * as React from "react";

import { Dropdown, DropdownItemProps } from "semantic-ui-react";

interface SortItem{
    text: string;
    value: string;
}

interface GridSortingProps{
    sortChange: (sortBy: any) => void;
    sortItems: SortItem[];
}

class DropDownSortMenu extends React.Component<GridSortingProps> {

    private selectSort = (e: any, data: DropdownItemProps) => {
        this.props.sortChange(data.value);
    };

    public render() {
        return (
            <Dropdown text="Sort" icon="sort" className="icon" floating labeled button>
                <Dropdown.Menu>
                    <Dropdown.Header icon="tags" content="sort by" />
                    <Dropdown.Divider />

                    {this.props.sortItems.map((item, index) =>
                        (<Dropdown.Item
                            key={index}
                            value={item.value}
                            onClick={this.selectSort}
                            text={item.text}
                        />)
                    )}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

}

export {
    DropDownSortMenu
};
