import { Payload } from "@neworbit/redux-helpers";

import * as actionTypes from "./actionTypes";
import * as models from "./model";

export type InvoicePeriodAction =
    ({ type: actionTypes.LOAD_INVOICE_PERIODS_SUCCESS } & Payload<models.InvoicePeriod[]>
    | { type: actionTypes.LOAD_INVOICE_PERIOD_SUCCESS } & Payload<models.InvoicePeriod>
    | { type: actionTypes.SAVE_INVOICE_PERIOD_SUCCESS } & Payload<models.InvoicePeriod>);

export function loadInvoicePeriodsSuccess(payload: models.InvoicePeriod[]): InvoicePeriodAction {
    return {
        type: actionTypes.LOAD_INVOICE_PERIODS_SUCCESS,
        payload
    };
}

export function loadInvoicePeriodSuccess(payload: models.InvoicePeriod): InvoicePeriodAction {
    return {
        type: actionTypes.LOAD_INVOICE_PERIOD_SUCCESS,
        payload
    };
}

export function saveInvoicePeriodSuccess(payload: models.InvoicePeriod): InvoicePeriodAction {
    return {
        type: actionTypes.SAVE_INVOICE_PERIOD_SUCCESS,
        payload
    };
}
