import * as React from "react";
import { Grid, Table } from "semantic-ui-react";
import { InvoicePeriod } from "@momenta/common/invoicePeriods";

import { InvoicePeriodRow } from "./InvoicePeriodRow";

interface InvoicePeriodsTableProps {
    invoicePeriods: InvoicePeriod[];
    basePath: string;
    onProcessedChanged: (id: number, value: boolean) => void;
}

const InvoicePeriodsTable: React.FC<InvoicePeriodsTableProps> = ({ invoicePeriods, basePath, onProcessedChanged }) => {

    return (
        <Grid.Row>
            <Grid.Column>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Associate Name</Table.HeaderCell>
                            <Table.HeaderCell>Payment Due Date</Table.HeaderCell>
                            <Table.HeaderCell>Invoice Date</Table.HeaderCell>
                            <Table.HeaderCell>Invoice Number</Table.HeaderCell>
                            <Table.HeaderCell>Momenta Company Name</Table.HeaderCell>
                            <Table.HeaderCell>Total Payment</Table.HeaderCell>
                            <Table.HeaderCell>Invoice Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {invoicePeriods.length === 0 && (
                            <Table.Row>
                                <Table.Cell content="No invoice periods found" colSpan={8} textAlign="center" disabled />
                            </Table.Row>
                        )}
                        {invoicePeriods && invoicePeriods.map(invoicePeriod => {
                            return (
                                <InvoicePeriodRow
                                    key={invoicePeriod.id}
                                    invoicePeriod={invoicePeriod}
                                    basePath={basePath}
                                    onProcessedChanged={onProcessedChanged}
                                />
                            );
                        })}
                    </Table.Body>
                </Table>
            </Grid.Column>
        </Grid.Row>

    );
};

export {
    InvoicePeriodsTable
};
