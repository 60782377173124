import moment from "moment";

import { parseTimesheet } from "../timesheets";
import { createEmptyState } from "../utils/createEmptyState";

import { InvoiceData, InvoicePeriod } from "./model";

export const parseInvoicePeriod = (model: InvoicePeriod) => {
    return {
        ...model,
        start: moment.utc(model.start),
        end: moment.utc(model.end),
        paymentDueDate: moment.utc(model.paymentDueDate),
        timesheets: model.timesheets && model.timesheets.map(t => parseTimesheet(t)),
        invoiceData: model.invoiceData !== undefined && parseInvoiceData(model.invoiceData as any),
        processed: model.processed,
        invoiceDate: model.invoiceDate && moment.utc(model.invoiceDate)
    };
};

const parseInvoiceData = (invoiceData: string): InvoiceData => {

    if (invoiceData === null) {
        return createEmptyState<InvoiceData>();
    }

    const model = JSON.parse(invoiceData);

    return {
        ...model,
        invoiceStartDate: model.invoiceStartDate && moment.utc(model.invoiceStartDate),
        invoiceEndDate: model.invoiceEndDate && moment.utc(model.invoiceEndDate),
        invoiceDate: model.invoiceDate && moment.utc(model.invoiceDate)
    };
};
