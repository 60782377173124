import * as React from "react";
import { Divider } from "semantic-ui-react";
import { InvoicePeriod, InvoicePeriods } from "@momenta/common/invoicePeriods";
import { Timesheet, TimesheetList } from "@momenta/common/timesheets";
import { LoadingWrapper } from "@momenta/common";

interface InvoicesProps {
    invoicePeriods: InvoicePeriod[];
    total: number;
    basePath?: string;
    loading: boolean;
    allowPrinting?: boolean;
}

export const InvoicesUnconnected: React.FC<InvoicesProps> = ({ invoicePeriods, total, basePath, loading, allowPrinting }) => {

    const timesheetList = (timesheets: Timesheet[]): JSX.Element => {
        return (
            <TimesheetList timesheets={timesheets} basePath={basePath} />
        );
    };

    return (
        <LoadingWrapper loading={loading}>
            <Divider />

            <InvoicePeriods
                invoicePeriods={invoicePeriods}
                total={total}
                timesheetList={timesheetList}
                path={basePath}
                allowPrinting={allowPrinting}
            />
        </LoadingWrapper>
    );
};
