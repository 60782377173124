import axios, { AxiosStatic } from "axios";
import { makeRequest, PageResult } from "@momenta/common";
import { InvoicePeriod, parseInvoicePeriod } from "@momenta/common/invoicePeriods";
import { ProcessInvoiceModel } from "@momenta/common/invoicePeriods/model";

import { MULTIPLE_REQUEST_CANCELLATION } from "../messages";

class InvoicePeriodApiUnconnected {

    private readonly pageSize = 20;
    private readonly apiUrl = "/api/invoiceperiod";
    private axios: AxiosStatic;

    private allCancelTokenSource = axios.CancelToken.source();

    constructor(ax: AxiosStatic) {
        this.axios = ax;
    }

    public async getAll(pageNumber: number = 1, filter?: string, orderBy: string = "InvoiceDate desc"): Promise<PageResult<InvoicePeriod>> {
        this.allCancelTokenSource.cancel(MULTIPLE_REQUEST_CANCELLATION);
        this.allCancelTokenSource = axios.CancelToken.source();

        const skip = (pageNumber - 1) * this.pageSize;

        const options = {
            count: true,
            skip,
            top: this.pageSize,
            orderBy,
            filter
        };

        const request = makeRequest(`${this.apiUrl}`, options);

        const response = await this.axios.get(request, { cancelToken: this.allCancelTokenSource.token });
        const { items, count } = response.data as PageResult<InvoicePeriod>;

        return {
            items: items.map(d => parseInvoicePeriod(d)),
            count
        };
    }

    public async getAssociateInvoicePeriods(pageNumber: number = 1, associateId: number, filter?: string): Promise<PageResult<InvoicePeriod>> {
        this.allCancelTokenSource.cancel(MULTIPLE_REQUEST_CANCELLATION);
        this.allCancelTokenSource = axios.CancelToken.source();

        const skip = (pageNumber - 1) * this.pageSize;

        const options = {
            count: true,
            skip,
            top: this.pageSize,
            orderby: "Id desc",
            filter
        };

        const request = makeRequest(`/api/associate/${associateId}/invoicePeriod`, options);

        const response = await this.axios.get(request, { cancelToken: this.allCancelTokenSource.token });
        const { items, count } = response.data as PageResult<InvoicePeriod>;

        return {
            items: items.map(d => parseInvoicePeriod(d)),
            count
        };
    }

    public async get(invoicePeriodId: number): Promise<InvoicePeriod> {
        const response = await this.axios.get(`${this.apiUrl}/${invoicePeriodId}`);
        const invoicePeriod = response.data as InvoicePeriod;
        return parseInvoicePeriod(invoicePeriod);
    }

    public async process(model: ProcessInvoiceModel[]): Promise<number> {
        const response = await axios.put(`${this.apiUrl}/process`, { invoices: model });
        return response.status;
    }
}

const InvoicePeriodApi = new InvoicePeriodApiUnconnected(axios);

export {
    InvoicePeriodApi
};
