export const LOAD_TIMESHEETS_SUCCESS = "LOAD_TIMESHEETS_SUCCESS";
export type LOAD_TIMESHEETS_SUCCESS = typeof LOAD_TIMESHEETS_SUCCESS;

export const LOAD_TIMESHEET_SUCCESS = "LOAD_TIMESHEET_SUCCESS";
export type LOAD_TIMESHEET_SUCCESS = typeof LOAD_TIMESHEET_SUCCESS;

export const APPROVE_TIMESHEET_SUCCESS = "APPROVE_TIMESHEET_SUCCESS";
export type APPROVE_TIMESHEET_SUCCESS = typeof APPROVE_TIMESHEET_SUCCESS;

export const SAVE_TIMESHEET_SUCCESS = "SAVE_TIMESHEET_SUCCESS";
export type SAVE_TIMESHEET_SUCCESS = typeof SAVE_TIMESHEET_SUCCESS;
