import * as React from "react";
import { Table } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { Link } from "redux-little-router";
import { getInvoicePeriodNumberFormatted } from "@momenta/common/invoicePeriods";
import { getFormattedCurrencyNumber } from "@momenta/common";
import { InvoicePeriod } from "@momenta/common/invoicePeriods/model";
import { Moment } from "moment";

interface InvoicePeriodRowStateProps {
    invoicePeriod: InvoicePeriod;
    basePath: string;
    onProcessedChanged: (id: number, value: boolean) => void;
}

const InvoicePeriodRow: React.FC<InvoicePeriodRowStateProps> =
    ({ invoicePeriod, basePath, onProcessedChanged }) => {
        const [isChecked, setChecked] = React.useState(invoicePeriod.processed);
        const momentaCompany = invoicePeriod?.candidate?.role?.project?.momentaCompany;

        const updateProcessedState = (value: boolean) => {
            if (value === isChecked) {
                return;
            }

            setChecked(value);
            onProcessedChanged(invoicePeriod.id, value);
        };

        const getValidDate = (date: Moment) => date?.isValid() ? date.format("DD/MM/YYYY") : "";

        const invoiceDate = getValidDate(invoicePeriod?.invoiceDate);
        const paymentDueDate = getValidDate(invoicePeriod?.paymentDueDate);

        return (
            <Table.Row key={invoicePeriod?.id}>
                <Table.Cell>
                    <Link href={`${basePath}/associate/${invoicePeriod?.candidate?.associate?.id}`}>
                        {invoicePeriod?.candidate?.associate?.fullName}
                    </Link>
                </Table.Cell>
                <Table.Cell content={paymentDueDate}></Table.Cell>
                <Table.Cell content={invoiceDate}></Table.Cell>
                <Table.Cell content={getInvoicePeriodNumberFormatted(invoicePeriod?.id)}></Table.Cell>
                <Table.Cell>{momentaCompany?.name}</Table.Cell>
                <Table.Cell>{getFormattedCurrencyNumber(invoicePeriod?.total)}</Table.Cell>
                <Table.Cell>
                    <Input.Checkbox
                        value={isChecked}
                        onChange={updateProcessedState}
                        label="Processed"
                    />
                </Table.Cell>
            </Table.Row>
        );
    };

export {
    InvoicePeriodRow
};
