import { initializeCurrentLocation, replace } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";

import { AppState } from "../model";
import { currentUserApi, getCurrentUserSuccess } from "../auth";

const initialise = async (dispatch: any, getState: () => AppState) => {

    const result = await loadAndTrack(dispatch, "getCurrentUser", currentUserApi.get());
    await dispatch(getCurrentUserSuccess(result));

    const state = getState();
    let initialLocation = state.router;

    if (state.currentUser.authenticated && initialLocation.pathname === "/") {
        dispatch(replace("/invoicePeriods"));
        return;
    }

    initialLocation = getState().router;

    if (initialLocation) {
        dispatch(initializeCurrentLocation(initialLocation));
    }
};

export function initialiseLocation() {
    return async (dispatch: any, getState: () => AppState) => {
        await initialise(dispatch, getState);
    };
}
