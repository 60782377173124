import * as React from "react";
import { Segment } from "semantic-ui-react";
import { InvoicePeriodCard } from "@momenta/common/invoicePeriods/InvoicePeriodCard";

import { Pagination } from "../pagination";
import { Timesheet } from "../timesheets";

import { InvoicePeriod } from "./model";

export interface InvoicesProps {
    invoicePeriods: InvoicePeriod[];
    total?: number;
    path?: string;
    timesheetList: (timesheets: Timesheet[]) => JSX.Element;
    allowPrinting?: boolean;
}

export const InvoicePeriods: React.FC<InvoicesProps> = ({ invoicePeriods, path, total, timesheetList, allowPrinting }) => {

    const associateName = invoicePeriods.length ? invoicePeriods[0].candidate?.associate?.fullName : "";

    return (
        <>
            {invoicePeriods.length === 0 &&
                <Segment textAlign="center" disabled basic>
                    No invoices
                </Segment>
            }
            <p>{associateName}</p>
            {invoicePeriods.map((invoicePeriod, index) => (
                <>
                    <InvoicePeriodCard
                        allowPrinting={allowPrinting}
                        key={invoicePeriod.id}
                        invoicePeriod={invoicePeriod}
                        timesheetList={timesheetList}
                        path={path}
                        firstCard={index === 0}
                    />
                </>
            ))}

            <Pagination total={total} />
        </>
    );
};
