/* eslint-disable max-classes-per-file */
import moment from "moment";
import { InvoiceData, InvoicePeriod } from "@momenta/common/invoicePeriods/model";
import { Company } from "@momenta/common/companies";

import { Candidate } from "../../People/src/candidates";
import { Timesheet } from "../timesheets";
import { MomentaCompany } from "../../People/src/momentaCompany/model";
import { Associate } from "../../People/src/associate/model";

export class InvoicePeriodBuilder {
    private id: number;
    private candidateId: number;
    private candidate: Candidate;
    private duration: number;
    private start: moment.Moment;
    private end: moment.Moment;
    private timesheets: Timesheet[];
    private vat: number;
    private paymentDueDate: moment.Moment;
    private associateInvoiceBatchId: number;
    private invoiceData: InvoiceData;
    private exceptionReason: string;
    private allServicesFee: number;
    private allBonusFee: number;
    private allExpenseFee: number;
    private subTotal: number;
    private total: number;
    private vatRegistered: boolean;
    private invoiceDate: moment.Moment;
    private processed: boolean;

    constructor() {
        this.id = 0;
        this.candidateId = 0;
        this.candidate = undefined;
        this.duration = 0;
        this.start = moment();
        this.end = moment();
        this.timesheets = undefined;
        this.vat = 0;
        this.paymentDueDate = moment();
        this.associateInvoiceBatchId = 0;
        this.exceptionReason = null;
        this.allServicesFee = 0;
        this.allBonusFee = 0;
        this.allExpenseFee = 0;
        this.subTotal = 0;
        this.total = 0;
        this.vatRegistered = false;
        this.processed = false;
        this.invoiceDate = null;
    }

    public withId(id: number): InvoicePeriodBuilder {
        this.id = id;
        return this;
    }

    public withCandidateId(candidateId: number): InvoicePeriodBuilder {
        this.candidateId = candidateId;
        return this;
    }

    public withCandidate(candidate: Candidate): InvoicePeriodBuilder {
        this.candidate = candidate;
        return this;
    }

    public withDuration(duration: number): InvoicePeriodBuilder {
        this.duration = duration;
        return this;
    }

    public withStart(start: moment.Moment): InvoicePeriodBuilder {
        this.start = start;
        return this;
    }

    public withEnd(end: moment.Moment): InvoicePeriodBuilder {
        this.end = end;
        return this;
    }

    public withTimesheets(timesheets: Timesheet[]): InvoicePeriodBuilder {
        this.timesheets = timesheets;
        return this;
    }

    public withVat(vat: number): InvoicePeriodBuilder {
        this.vat = vat;
        return this;
    }

    public withPaymentDueDate(paymentDueDate: moment.Moment): InvoicePeriodBuilder {
        this.paymentDueDate = paymentDueDate;
        return this;
    }

    public withAssociateInvoiceBatchId(associateInvoiceBatchId: number): InvoicePeriodBuilder {
        this.associateInvoiceBatchId = associateInvoiceBatchId;
        return this;
    }

    public withInvoiceData(invoiceData: InvoiceData): InvoicePeriodBuilder {
        this.invoiceData = invoiceData;
        return this;
    }

    public withExceptionReason(exceptionReason: string): InvoicePeriodBuilder {
        this.exceptionReason = exceptionReason;
        return this;
    }

    public withTotal(total: number): InvoicePeriodBuilder {
        this.total = total;
        return this;
    }

    public withSubTotal(subTotal: number): InvoicePeriodBuilder {
        this.subTotal = subTotal;
        return this;
    }

    public withAllServiceFee(allServicesFee: number): InvoicePeriodBuilder {
        this.allServicesFee = allServicesFee;
        return this;
    }

    public withAllBonusFee(allBonusFee: number): InvoicePeriodBuilder {
        this.allBonusFee = allBonusFee;
        return this;
    }

    public withAllExpenseFee(allExpenseFee: number): InvoicePeriodBuilder {
        this.allExpenseFee = allExpenseFee;
        return this;
    }

    public withVatRegistered(vatRegistered: boolean): InvoicePeriodBuilder {
        this.vatRegistered = vatRegistered;
        return this;
    }

    public withInvoiceDate(invoiceDate: moment.Moment) {
        this.invoiceDate = invoiceDate;
        return this;
    }

    public withProcessed(processed: boolean): InvoicePeriodBuilder {
        this.processed = processed;
        return this;
    }

    public build(): InvoicePeriod {
        return {
            id: this.id,
            candidateId: this.candidateId,
            candidate: this.candidate,
            duration: this.duration,
            start: this.start,
            end: this.end,
            timesheets: this.timesheets,
            vat: this.vat,
            paymentDueDate: this.paymentDueDate,
            associateInvoiceBatchId: this.associateInvoiceBatchId,
            invoiceData: this.invoiceData,
            exceptionReason: this.exceptionReason,
            total: this.total,
            subTotal: this.subTotal,
            allBonusFee: this.allBonusFee,
            allExpenseFee: this.allExpenseFee,
            allServicesFee: this.allServicesFee,
            vatRegistered: this.vatRegistered,
            processed: this.processed,
            invoiceDate: this.invoiceDate
        };
    }
}

export class InvoiceDataBuilder {
    private company: Company;
    private momentaCompany: MomentaCompany;
    private associate: Associate;
    private invoiceNumber: number;
    private invoiceStartDate: moment.Moment;
    private invoiceEndDate: moment.Moment;
    private invoiceDate: moment.Moment;
    private clientName: string;
    private projectName: string;
    private adjustmentTotal: number;

    constructor() {
        this.company = undefined;
        this.momentaCompany = undefined;
        this.associate = undefined;
        this.invoiceNumber = 0;
        this.invoiceStartDate = undefined;
        this.invoiceEndDate = undefined;
        this.invoiceDate = undefined;
        this.clientName = undefined;
        this.projectName = undefined;
        this.adjustmentTotal = 0;
    }

    public withCompany(company: Company) {
        this.company = company;
        return this;
    }

    public withMomentaCompany(momentaCompany: MomentaCompany) {
        this.momentaCompany = momentaCompany;
        return this;
    }

    public withAssociate(associate: Associate) {
        this.associate = associate;
        return this;
    }

    public withInvoiceNumber(invoiceNumber: number) {
        this.invoiceNumber = invoiceNumber;
        return this;
    }

    public withInvoiceStartDate(startDate: moment.Moment) {
        this.invoiceStartDate = startDate;
        return this;
    }

    public withInvoiceEndDate(endDate: moment.Moment) {
        this.invoiceEndDate = endDate;
        return this;
    }

    public withInvoiceDate(invoiceDate: moment.Moment) {
        this.invoiceDate = invoiceDate;
        return this;
    }

    public withClientName(clientName: string) {
        this.clientName = clientName;
        return this;
    }

    public withProjectName(projectName: string) {
        this.projectName = projectName;
        return this;
    }

    public withAdjustmentTotal(adjustmentTotal: number) {
        this.adjustmentTotal = adjustmentTotal;
        return this;
    }

    public build(): InvoiceData {
        return {
            version: "1",
            company: this.company,
            momentaCompany: this.momentaCompany,
            associate: this.associate,
            invoiceNumber: this.invoiceNumber,
            invoiceStartDate: this.invoiceStartDate,
            invoiceEndDate: this.invoiceEndDate,
            invoiceDate: this.invoiceDate,
            clientName: this.clientName,
            projectName: this.projectName,
            adjustmentTotal: this.adjustmentTotal
        };
    }
}
