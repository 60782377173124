import { Pagination } from "./Pagination";
import { PaginationComponent } from "./PaginationComponent";

export { loadPaginationTotalSuccess } from "./actions";
export { pagination as reducer } from "./reducer";
export { PaginationState } from "./model";
export { totalSelector, pageSelector } from "./selectors";
export {
    Pagination,
    PaginationComponent
};
