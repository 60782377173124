import { Dispatch } from "redux";
import { Payload } from "@neworbit/redux-helpers";
import { loadAndTrack } from "redux-request-loading";
import { loadPaginationTotalSuccess } from "@momenta/common/pagination";
import { InvoicePeriod } from "@momenta/common/invoicePeriods";
import { AppState, ProcessInvoiceModel } from "@momenta/common/invoicePeriods/model";
import { loadInvoicePeriodSuccess } from "@momenta/common/invoicePeriods/actions";
import * as actionTypes from "@momenta/common/invoicePeriods/actionTypes";
import { FilterBuilder } from "@momenta/common/odata";

import Axios from "axios";

import { MULTIPLE_REQUEST_CANCELLATION } from "../messages";

import { InvoicePeriodApi } from "./InvoicePeriodApi";
import { getInvoicePeriodFilter } from "./getInvoicePeriodFilter";

export type InvoicePeriodAction = ({ type: actionTypes.LOAD_INVOICE_PERIODS_SUCCESS } & Payload<InvoicePeriod[]>);

export function loadInvoicePeriodsSuccess(payload: InvoicePeriod[]): InvoicePeriodAction {
    return {
        type: actionTypes.LOAD_INVOICE_PERIODS_SUCCESS,
        payload
    };
}

export function loadInvoicePeriods() {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
        const pageNumber = +(getState().router.query.page || 1);
        const { associateName, invoiceNumber, paymentDueDate, processed, orderBy } = getState().router.query;

        const filter = getInvoicePeriodFilter(associateName, invoiceNumber, paymentDueDate, processed === "true");

        try {
            const { items, count } = await loadAndTrack(dispatch, "loadInvoicePeriods", InvoicePeriodApi.getAll(pageNumber, filter, orderBy));
            dispatch(loadInvoicePeriodsSuccess(items));
            dispatch(loadPaginationTotalSuccess(count));
        } catch (ex) {
            if ((ex instanceof Axios.Cancel) && ex.message !== MULTIPLE_REQUEST_CANCELLATION || (ex instanceof Axios.Cancel) === false) {
                throw ex;
            }
        }
    };
}

export function loadAssociateInvoicePeriods({ associateId }: { associateId: number }) {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
        const pageNumber = +(getState().router.query.page || 1);

        const invoiceFilter = new FilterBuilder<InvoicePeriod>()
            .and("candidate", "associateId", "eq", +associateId)
            .build();

        try {
            const { items, count } = await loadAndTrack(
                dispatch,
                "loadInvoicePeriods",
                InvoicePeriodApi.getAssociateInvoicePeriods(pageNumber, associateId, invoiceFilter)
            );
            dispatch(loadInvoicePeriodsSuccess(items));
            dispatch(loadPaginationTotalSuccess(count));
        } catch (ex) {
            if ((ex instanceof Axios.Cancel) && ex.message !== MULTIPLE_REQUEST_CANCELLATION || (ex instanceof Axios.Cancel) === false) {
                throw ex;
            }
        }
    };
}

export function loadInvoicePeriod({ invoicePeriodId }: { invoicePeriodId: string | number }) {
    return async (dispatch: Dispatch<any>) => {
        const result = await loadAndTrack(dispatch, "loadInvoicePeriod", InvoicePeriodApi.get(+invoicePeriodId));
        dispatch(loadInvoicePeriodSuccess(result));
    };
}

export function processInvoicePeriod(invoices: ProcessInvoiceModel[]) {
    return async (dispatch: Dispatch<any>) => {
        const model: ProcessInvoiceModel[] = invoices;
        await loadAndTrack(dispatch, "processInvoicePeriod", InvoicePeriodApi.process(model));
        dispatch(loadInvoicePeriods());
    };
}
