import * as React from "react";
import { Container, Divider, Header } from "semantic-ui-react";

import { InvoicePeriod } from "../invoicePeriods";
import { CdnImage } from "../CdnImage";

import { InvoiceDetail } from "./InvoiceDetail";

export interface InvoiceDetailStateProps {
    invoicePeriod: InvoicePeriod;
    path: string;
    canEdit: boolean;
}

export interface InvoiceDetailDispatchProps {
    save?: (invoicePeriodId: number, note: string) => Promise<void>;
}

export const InvoiceDetails: React.FC<InvoiceDetailStateProps & InvoiceDetailDispatchProps> = ({ invoicePeriod, canEdit, save }) => {

    const invoiceTitle = invoicePeriod && invoicePeriod.total < 0 ? "CREDIT NOTE" : "Self-Billing Invoice";

    return (
        <Container text className="invoice-details">
            <CdnImage centered src="/momenta-logo.png" alt="momenta logo with blue text" className="logo" />

            <Header as="h1" textAlign="center">{invoiceTitle}</Header>

            <Divider />

            {invoicePeriod &&
                <InvoiceDetail
                    data={invoicePeriod.invoiceData}
                    note={invoicePeriod.note}
                    canEdit={canEdit}
                    save={save}
                    invoicePeriodId={invoicePeriod.id}
                    invoicePeriod={invoicePeriod}
                />
            }
        </Container>
    );
};
