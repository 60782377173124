import { State as RouterState } from "redux-little-router";
import { InvoicePeriod, InvoicePeriodState } from "@momenta/common/invoicePeriods";
import { createSelector } from "reselect";

import { AppState } from "./model";

export const routeIdSelector = (state: RouterState) => {
    return +state.router.params.invoicePeriodId;
};

export const invoicePeriodsSelector = (state: InvoicePeriodState & RouterState) => state.invoicePeriods;

export const invoicePeriodSelector = createSelector(
    invoicePeriodsSelector,
    routeIdSelector,
    (invoicePeriods: InvoicePeriod[], invoicePeriodId: number) => invoicePeriods.filter(i => i.id === invoicePeriodId)[0]
);

export const associateIdSelector = (state: RouterState) => +state.router.params.associateId;

export const associateInvoicePeriodsSelector = createSelector(
    invoicePeriodsSelector,
    associateIdSelector,
    (invoicePeriods: InvoicePeriod[], associateId: number) => invoicePeriods.filter(i => i.candidate.associateId === associateId)
);

export const associateNameSelector = createSelector(
    invoicePeriodsSelector,
    associateIdSelector,
    (invoicePeriods: InvoicePeriod[], associateId: number) => {
        return invoicePeriods.filter(i => i.candidate.associateId === associateId).map(i => i.candidate.associate.fullName)[0];
    }
);

export const basePathSelector = (state: AppState) =>
    state.router.pathname.substring(0, state.router.pathname.indexOf("invoicePeriods") + "invoicePeriods".length);
