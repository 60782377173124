import { LoadingWrapper } from "@momenta/common";
import { DropDownSortMenu } from "@momenta/common/DropDownSortMenu";
import * as React from "react";
import { Link } from "redux-little-router";
import { Container, Dropdown, Form, Grid, Menu } from "semantic-ui-react";

import { ContractTableFilter } from "../components/ContractsTableFilter";

import { ContractTable } from "./ContractsTable";

export const ContractDetails: React.FC = () => {
    return (
        <>
            <LoadingWrapper loading={false}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={13}>
                            <h1>Contracts</h1>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={2}>
                            <Menu compact>
                                <Menu.Item
                                    name='Invoices'
                                    as={Link}
                                    href={"/invoicePeriods"}
                                >
                                    Invoices
                                </Menu.Item>
                            </Menu>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <ContractTableFilter />
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={16}>
                            <ContractTable contracts={[]}></ContractTable>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </LoadingWrapper>
        </>
    );
};
