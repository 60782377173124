import * as React from "react";
import { connect } from "react-redux";
import { LoadingState } from "redux-request-loading";
import { AppState, getEndDateTimesheet, Timesheet } from "@momenta/common/timesheets";
import { BonusTypeConfiguration, ConfigurationState, ExpenseTypeConfiguration, TimeTypeConfiguration } from "@momenta/common/hierarchicalConfiguration";
import { TimesheetViews } from "@momenta/common/timesheets/TimesheetViews";

import { timesheetSelector } from "./selectors";

interface TimesheetDetailProps {
    timesheet: Timesheet;
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
    timeTypeConfigurations: TimeTypeConfiguration[];
    bonusTypeConfigurations: BonusTypeConfiguration[];
}

export class TimesheetDetailUnconnected extends React.Component<TimesheetDetailProps> {
    public render() {
        const { timesheet, timeTypeConfigurations, bonusTypeConfigurations, expenseTypeConfigurations } = this.props;
        const startDate = timesheet && timesheet.start;
        const dateRange = startDate && `${startDate.format("LL")} - ${getEndDateTimesheet(this.props.timesheet).format("LL")}`;

        return (
            <>
                {timesheet &&
                    <TimesheetViews
                        dateRange={dateRange}
                        timesheet={timesheet}
                        timeTypeConfigurations={timeTypeConfigurations}
                        bonusTypeConfigurations={bonusTypeConfigurations}
                        expenseTypeConfigurations={expenseTypeConfigurations}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = (state: AppState & ConfigurationState & LoadingState): TimesheetDetailProps => {
    return {
        timesheet: timesheetSelector(state),
        timeTypeConfigurations: state.timeTypeConfiguration,
        expenseTypeConfigurations: state.expenseTypeConfiguration,
        bonusTypeConfigurations: state.bonusTypeConfiguration
    };
};

export const TimesheetDetail = connect(mapStateToProps)(TimesheetDetailUnconnected);
