import * as React from "react";
import { Button, Form, Message, Segment } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";

import LoadingWrapper from "../LoadingWrapper";

interface InvoiceNoteProps {
    note?: string;
    invoicePeriodId: number;
    canEdit: boolean;
    save: (invoicePeriodId: number, note: string) => Promise<void>;
}

interface InvoiceNoteState {
    note: string;
    loading: boolean;
}

export class InvoiceNote extends React.Component<InvoiceNoteProps, InvoiceNoteState> {
    public state: InvoiceNoteState = {
        note: this.props.note,
        loading: false
    };

    public render() {
        const { note, canEdit } = this.props;

        return (
            <Segment className="no-padding" clearing basic>
                {canEdit &&
                    <LoadingWrapper loading={this.state.loading}>
                        <Form noValidate>
                            <Input.Textarea
                                value={this.state.note}
                                label="Note"
                                onChange={this.onHandleMessageChange}
                                readOnly={false}
                                autoHeight
                            />

                            <Button content="Save" icon="save outline" onClick={this.onSave} floated="right" disabled={this.state.loading} positive />
                        </Form>
                    </LoadingWrapper>
                }
                {canEdit === false &&
                    <Message>
                        <p>{note}</p>
                    </Message>
                }
            </Segment>
        );
    }

    private onHandleMessageChange = (value: string) => this.setState({ note: value });

    private onSave = async () => {
        this.setState({ loading: true });
        await this.props.save(this.props.invoicePeriodId, this.state.note);
        this.setState({ loading: false });
    }
}
