import * as React from "react";
import { Fragment } from "redux-little-router";

import { TimesheetDetail } from "../timesheets/TimesheetDetail";

import { InvoiceDetails } from "./InvoiceDetails";
import { Invoices } from "./Invoices";
import { InvoicePeriodDetails } from "./InvoicePeriodDetails";

export const InvoicePeriods: React.SFC = () => (
    <>
        <Fragment forRoute="/associate/:associateId">
            <>
                <Fragment forRoute="/invoicePeriod/:invoicePeriodId">
                    <InvoiceDetails />
                </Fragment>
                <Fragment forRoute="/timesheets/:timesheetId">
                    <TimesheetDetail />
                </Fragment>
                <Fragment forRoute="/">
                    <Invoices />
                </Fragment>
            </>
        </Fragment>
        <Fragment forRoute="/">
            <InvoicePeriodDetails />
        </Fragment>
    </>
);
