import moment from "moment";
import { FilterBuilder, MomentValue } from "@momenta/common/odata";
import { InvoicePeriod } from "@momenta/common/invoicePeriods";
import { getInvoicePeriodNumberUnformatted } from "@momenta/common/getInvoicePeriodNumberFormatted";

export const getInvoicePeriodFilter = (associateName: string, invoiceNumber: string, paymentDueDate: string, processed: any) => {
    const paymentDue = paymentDueDate ? moment(paymentDueDate) : undefined;
    const paymentDueEnd = paymentDue && paymentDue.clone().add(1, "days");

    return new FilterBuilder<InvoicePeriod>()
        .and("id", "eq", getInvoicePeriodNumberUnformatted(invoiceNumber))
        .and("candidate", "associate", "forename", "startsWith", associateName)
        .and("paymentDueDate", "ge", new MomentValue(paymentDue))
        .and("paymentDueDate", "lt", new MomentValue(paymentDueEnd))
        .and("processed", "eq", processed)
        .build();
};
