import { Payload } from "@neworbit/redux-helpers";

import * as actions from "./actionTypes";

export type PaginationAction = ({ type: actions.LOAD_PAGINATION_TOTAL_SUCCESS } & Payload<{ [key: string]: number }>);

export const loadPaginationTotalSuccess = (count: number, key: string = "total"): PaginationAction => ({
    type: actions.LOAD_PAGINATION_TOTAL_SUCCESS,
    payload: {
        [key]: count
    }
});
