import thunk from "redux-thunk";
import { Middleware } from "redux";
import { Location, LOCATION_CHANGED, replace } from "redux-little-router";
import { AuthPayload, AuthState, configureAuthMiddleware } from "reauthorize";
import { configureDispatchActions } from "@neworbit/redux-helpers";
import { toastMiddleware } from "@momenta/common/toasts/toastMiddleware";
import { appInsightsMiddleware } from "@momenta/common/logging/appInsightsMiddleware";
import { createEmptyState } from "@momenta/common/utils/createEmptyState";

const dispatchActions = configureDispatchActions(
    LOCATION_CHANGED,
    action => (action.payload || {}).result,
    state => state.router.params
);

const authMiddleware = configureAuthMiddleware<AuthState, { payload: Location }>({
    actionType: LOCATION_CHANGED,
    getAuthPayload: action => (action.payload || createEmptyState<Location>()).result as AuthPayload,
    getUser: state => state.currentUser,
    unauthorizedAction: replace("/forbidden")
});

export const middlewares: Middleware[] = [
    appInsightsMiddleware,
    authMiddleware,
    toastMiddleware as Middleware,
    dispatchActions as Middleware,
    thunk
];
