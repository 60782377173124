import axios, { AxiosStatic } from "axios";

import { CurrentUser } from "./model";

export class CurrentUserApi {

    private readonly apiUrl = "/api/currentuser";
    private axios: AxiosStatic;

    constructor(ax: AxiosStatic) {
        this.axios = ax;
    }

    public async get(): Promise<CurrentUser> {
        const response = await this.axios.get(this.apiUrl);
        return response.data as CurrentUser;
    }
}

const currentUserApi = new CurrentUserApi(axios);

export { currentUserApi };
