import { Timesheet, TimesheetStateEnum } from "@momenta/common/timesheets";

import * as actions from "./actionTypes";
import { TimesheetAction } from "./actions";

export function timesheets(state: Timesheet[] = [], action: TimesheetAction): Timesheet[] {
    switch (action.type) {
        case actions.LOAD_TIMESHEETS_SUCCESS:
            return action.payload;
        case actions.LOAD_TIMESHEET_SUCCESS:
            if (state.filter(t => t.id === action.payload.id).length > 0) {
                return state.map(t => timesheet(t, action));
            }
            return [...state, action.payload];
        case actions.APPROVE_TIMESHEET_SUCCESS:
            return state.map(t => timesheet(t, action));
        default:
            return state;
    }
}

function timesheet(state: Timesheet, action: TimesheetAction): Timesheet {
    switch (action.type) {
        case actions.APPROVE_TIMESHEET_SUCCESS:
            if (action.payload === state.id) {
                return { ...state, state: TimesheetStateEnum.Approved };
            }
            return state;
        case actions.LOAD_TIMESHEET_SUCCESS:
            if (state.id === action.payload.id) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
}
