import { createSelector } from "reselect";
import { AppState, Timesheet } from "@momenta/common/timesheets";

const timesheetIdSelector = (state: AppState) => +state.router.params.timesheetId;

const timesheetsSelector = (state: AppState) => state.timesheets;

export const timesheetSelector = createSelector(
    timesheetsSelector,
    timesheetIdSelector,
    (timesheets: Timesheet[], id: number) => timesheets.filter(t => t.id === id)[0]
);
