import * as React from "react";
import { connect } from "react-redux";
import { push, State as RouterState } from "redux-little-router";
import { pageSelector } from "@momenta/common/pagination/selectors";

import { PaginationComponent } from "./PaginationComponent";

interface PaginationProps {
    total: number;
    pageSize?: number;
    loading?: boolean;
    onPageChange?: (page: number) => void;
}

interface PaginationStateProps {
    page: number;
    query: any;
}

interface PaginationDispatchProps {
    pageChanged: (page: number) => Promise<void>;
}

export class PaginationUnconnected extends React.Component<PaginationProps & PaginationStateProps & PaginationDispatchProps> {

    constructor(props: PaginationProps & PaginationStateProps & PaginationDispatchProps) {
        super(props);

        this.onPageChange = this.onPageChange.bind(this);
    }

    public render() {

        const page = this.props.page || 1;
        const pageSize = this.props.pageSize || 20;

        return (
            <PaginationComponent
                total={this.props.total}
                page={page}
                pageSize={pageSize}
                onPageChange={this.onPageChange}
                loading={this.props.loading}
            />
        );
    }

    private async onPageChange(page: number) {
        await this.props.pageChanged(page);

        if (this.props.onPageChange) {
            this.props.onPageChange(page);
        }
    }
}

interface PropsFromDispatch {
    setQuery: (routerQuery: any, page: number) => void;
}

const mapStateToProps = (state: RouterState): PaginationStateProps => ({
    page: pageSelector(state),
    query: state.router.query
});

const mapDispatchToProps = (dispatch: any): PropsFromDispatch => ({
    setQuery: (routerQuery, page) => dispatch(push({ query: { ...routerQuery, page: `${page}` }, pathname: undefined }))
});

const mergeProps = (
    propsFromState: PaginationStateProps,
    propsFromDispatch: PropsFromDispatch,
    ownProps: PaginationProps) => {
    return {
        ...propsFromState,
        ...ownProps,
        pageChanged: (page: number) => propsFromDispatch.setQuery(propsFromState.query, page)
    };
};

export const Pagination = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PaginationUnconnected);
