import * as React from "react";
import { InvoicePeriod } from "@momenta/common/invoicePeriods/model";
import { Button, Card, Grid, Icon } from "semantic-ui-react";
import { Timesheet } from "@momenta/common/timesheets/model";
import { Link } from "redux-little-router";
import { getInvoicePeriodNumberFormatted } from "@momenta/common/invoicePeriods/getInvoicePeriodNumberFormatted";

export interface InvoicePeriodCardProps {
    invoicePeriod: InvoicePeriod;
    timesheetList: (timesheets: Timesheet[]) => JSX.Element;
    path?: string;
    firstCard: boolean;
    allowPrinting?: boolean;
}

interface InvoicePeriodCardState {
    minimised: boolean;
}

export class InvoicePeriodCard extends React.Component<InvoicePeriodCardProps, InvoicePeriodCardState> {

    public state: InvoicePeriodCardState = {
        minimised: this.props.firstCard
    };

    public render() {
        const { invoicePeriod, timesheetList, allowPrinting } = this.props;
        const showInvoiceData = this.showInvoiceData();
        const showPaymentDueDate = this.showPaymentDueDate();

        const showBoth = showPaymentDueDate && showInvoiceData;
        const showEither = !showBoth && (showInvoiceData || showPaymentDueDate);
        const showEitherComputerWidth = showEither ? 12 : 15;
        const showEitherMobileWidth = showEither ? 11 : 14;
        const computerWidth = showBoth ? 9 : showEitherComputerWidth;
        const mobileWidth = showBoth ? 8 : showEitherMobileWidth;

        return (
            <Card fluid key={invoicePeriod.id}>
                <Card.Content onClick={this.toggleTimesheets} className="invoice-period">
                    <Grid verticalAlign="middle">
                        <Grid.Column computer={computerWidth} mobile={mobileWidth} tablet={computerWidth}>
                            <Icon name="file alternate outline" size="big" className="invoice-icon" />
                            <Card.Header as="strong">
                                {invoicePeriod.start.format("ll")} - {invoicePeriod.end.format("ll")}
                            </Card.Header>

                            {showInvoiceData &&
                                <Card.Meta>
                                    {getInvoicePeriodNumberFormatted(invoicePeriod.id)}
                                </Card.Meta>
                            }
                        </Grid.Column>
                        {showPaymentDueDate &&
                            <Grid.Column computer={3} mobile={3} tablet={3}>
                                <Card.Meta>
                                    Payment due
                                </Card.Meta>
                                <Card.Header>
                                    {invoicePeriod.paymentDueDate.format("ll")}
                                </Card.Header>
                            </Grid.Column>
                        }

                        {showInvoiceData &&
                            <Grid.Column computer={3} mobile={3} tablet={3} textAlign="left">
                                <Button size="mini" as={Link} href={`${this.props.path || ""}/invoicePeriod/${invoicePeriod.id}`}>
                                    View Invoice
                                </Button>
                                { allowPrinting &&
                                    <Button size="mini" positive as={Link} href={`${this.props.path || ""}/invoicePeriod/${invoicePeriod.id}/print`}>
                                        <Icon name="print" /> Print
                                    </Button>
                                }
                            </Grid.Column>
                        }

                        <Grid.Column computer={1} mobile={2} tablet={1} textAlign="center">
                            <Icon name={this.state.minimised ? "chevron up" : "chevron down"} fitted />
                        </Grid.Column>
                    </Grid>
                </Card.Content>
                {this.state.minimised && <Card.Content>
                    {timesheetList(invoicePeriod.timesheets)}
                </Card.Content>}
            </Card>
        );
    }

    private showInvoiceData = () => {
        const company = this.props.invoicePeriod?.candidate?.company;
        const generateInvoice = company?.generateInvoice;

        return this.props.invoicePeriod?.associateInvoiceBatchId != null && generateInvoice;
    }

    private showPaymentDueDate = () => {
        return this.showInvoiceData() && this.props.invoicePeriod.total > 0;
    }

    private toggleTimesheets = () => {
        this.setState(prevState => ({
            minimised: prevState.minimised ? false : true
        }));
    }
}
