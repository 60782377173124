import { AppState } from "@momenta/common/timesheets";
import { loadGlobalConfig } from "@momenta/common/globalConfig";

import { loadConfiguration } from "../hierarchicalConfiguration";

import { loadTimesheet, loadTimesheets } from "./actions";
import { timesheetSelector } from "./selectors";

export const routes = {
    "/timesheets": {
        "title": "Timesheets",
        "authorise": true,
        "resolve": loadTimesheets,
        "ignoreParentResolve": true,
        "/:timesheetId": {
            title: (state: AppState) => "Timesheet - " + timesheetSelector(state).start.format("L"),
            resolve: [
                loadTimesheet,
                loadConfiguration("timeTypeConfiguration"),
                loadConfiguration("expenseTypeConfiguration"),
                loadConfiguration("bonusTypeConfiguration"),
                loadGlobalConfig
            ]
        }
    },
};
