import { User } from "reauthorize";

export interface CurrentUser extends User {
    id?: number;
    emailAddress?: string;
    groups?: string[];
}

export interface AuthState {
    currentUser: CurrentUser;
}

export const UserRoles = {
    InvoiceProcessor: "InvoiceProcessor",
    ContractApprover: "ContractApprover"
};
