import * as actions from "./actionTypes";
import { PaginationAction } from "./actions";
import { PaginationData } from "./model";

export function pagination(state: PaginationData = {}, action: PaginationAction): PaginationData {
    switch (action.type) {
        case actions.LOAD_PAGINATION_TOTAL_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
