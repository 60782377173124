import { getReducer } from "@momenta/common/auth";
import { reducer as invoicePeriods } from "@momenta/common/invoicePeriods";
import { reducer as pagination } from "@momenta/common/pagination";
import { reducer as globalConfigs } from "@momenta/common/globalConfig";

import { CurrentUser } from "../auth";
import { reducer as timesheets } from "../timesheets";
import { createReducer } from "../hierarchicalConfiguration";

const currentUser = getReducer<CurrentUser>({ authenticated: false, roles: [] });
const timeTypeConfiguration = createReducer("timeTypeConfiguration");
const expenseTypeConfiguration = createReducer("expenseTypeConfiguration");
const bonusTypeConfiguration = createReducer("bonusTypeConfiguration");

export const reducers = {
    currentUser,
    invoicePeriods,
    pagination,
    timesheets,
    timeTypeConfiguration,
    expenseTypeConfiguration,
    bonusTypeConfiguration,
    globalConfigs
};
