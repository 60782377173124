export const getInvoicePeriodNumberFormatted = (invoicePeriodId: number): string => {
    const invoicePeriodNumber = `${invoicePeriodId}`;
    const zeros = "000000";
    const zeroPadding = zeros.substr(0, 5 - invoicePeriodNumber.length);
    const invoicePeriodNumberFormatted = `M${zeroPadding}${invoicePeriodNumber}`;

    return invoicePeriodNumberFormatted;
};

export const getInvoicePeriodNumberUnformatted = (invoiceNumber: string): number => {

    if (invoiceNumber == null) {
        return undefined;
    }

    if (invoiceNumber.length < 6) {
        return parseNumber(invoiceNumber);
    }

    const invoiceIdRegex = /M0*(\d+)/g;
    const match = invoiceIdRegex.exec(invoiceNumber);
    return parseNumber(match[1]);
};

export const parseNumber = (value: string) => value.length ? +value : undefined;
