import * as React from "react";
import { Container, Grid } from "semantic-ui-react";

export const Landing: React.FC = () => (
    <>
        <div className="landing">
            <Container>
                <Grid>
                    <Grid.Column computer={10} mobile={16}>
                        <h1>Momenta people umbrella portal</h1>

                        <p>Welcome to <span className="secondary-colour">Momenta People</span> umbrella portal.
                            Here you will review your employees’ timesheets, invoices and corresponding payment dates.</p>

                        <b>For more information or assistance contact: <a href="mailto:invoices@momentagroup.com">invoices@momentagroup.com</a></b>
                    </Grid.Column>
                </Grid>
            </Container>
        </div>
        <div className="landing-image">
        </div>
    </>
);
