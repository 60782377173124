import * as React from "react";
import { Form, Input as SearchInput, Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";

export const ContractTableFilter: React.FC = () => {
    return (
        <>
            <Grid.Column width={4}>
                <Form noValidate>
                    <Form.Field>
                        <label>
                            Search Associate Name
                        </label>
                        <SearchInput
                            placeholder="Search associate name"
                            action={{ icon: "search" }}
                        />
                    </Form.Field>
                </Form>
            </Grid.Column>
            <Grid.Column width={4}>
                <Form noValidate>
                    <Form.Field>
                        <label>
                            Search Client
                        </label>
                        <SearchInput
                            placeholder="Search client"
                            action={{ icon: "search" }}
                        />
                    </Form.Field>
                </Form>
            </Grid.Column>
            <Grid.Column width={4}>
                <Form noValidate>
                    <Form.Field>
                        <label>
                            Search Project
                        </label>
                        <SearchInput
                            placeholder="Search project"
                            action={{ icon: "search" }}
                        />
                    </Form.Field>
                </Form>
            </Grid.Column>
            <Grid.Column width={4}>
                <Form noValidate>
                    <Form.Field>
                        <label>
                            Search Role
                        </label>
                        <SearchInput
                            placeholder="Search role"
                            action={{ icon: "search" }}
                        />
                    </Form.Field>
                </Form>
            </Grid.Column>
            <Grid.Column width={4}>
                <Form>
                    <Form.Field>
                        <label>
                            Search Contract Start Date
                        </label>
                        <Input.Date
                        />
                    </Form.Field>
                </Form>
            </Grid.Column>
        </>
    );
};
