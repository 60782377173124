import { AppState } from "../model";
import { timesheetSelector } from "../timesheets/selectors";
import { loadTimesheet } from "../timesheets/actions";
import { loadConfiguration } from "../hierarchicalConfiguration";

import { loadAssociateInvoicePeriods, loadInvoicePeriods } from "./actions";

export const routes = {
    "/invoicePeriods": {
        "title": "Invoice Periods",
        "authorize": true,
        "ignoreParentResolve": true,
        "resolve": () => loadInvoicePeriods(),
        "/associate/:associateId": {
            "title": "Associate",
            "authorize": true,
            "resolve": loadAssociateInvoicePeriods,
            "/timesheets/:timesheetId": {
                title: (state: AppState) => {
                    const timesheet = timesheetSelector(state);
                    return timesheet != null ? "Timesheet - " + timesheet.start.format("L") : "";
                },
                authorize: true,
                resolve: [
                    loadTimesheet,
                    loadConfiguration("timeTypeConfiguration"),
                    loadConfiguration("expenseTypeConfiguration"),
                    loadConfiguration("bonusTypeConfiguration")
                ],
            },
            "/invoicePeriod/:invoicePeriodId": {
                title: "Invoice Period Details",
                authorize: true
            }
        }
    }
};
