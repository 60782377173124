import * as React from "react";
import { Grid, Table } from "semantic-ui-react";
import { getInvoicePeriodNumberFormatted, InvoicePeriod } from "@momenta/common/invoicePeriods";
import { Company } from "@momenta/common/companies";
import { InvoiceData } from "@momenta/common/invoicePeriods/model";

import { MomentaCompany } from "../../People/src/momentaCompany";
import { Currency } from "../internationalisation";

import { InvoiceNote } from "./InvoiceNote";

export interface InvoiceDetailProps {
    invoicePeriod: InvoicePeriod;
    data: InvoiceData;
    note?: string;
    canEdit: boolean;
    invoicePeriodId: number;
    save: (invoicePeriodId: number, note: string) => Promise<void>;
}

export const InvoiceDetail: React.SFC<InvoiceDetailProps> = ({ data, note, canEdit, save, invoicePeriodId, invoicePeriod }) => {
    const getAssociateCompanyAddress = (company: Company) => (
        <>
            <p>{company.address.line1}</p>
            <p>{company.address.line2}</p>
            <p>{company.address.city}</p>
            <p>{company.address.county}</p>
            <p>{company.address.postcode}</p>
            <p>{company.address.country}</p>
        </>
    );

    const getMomentaCompanyAddress = (momentaCompany: MomentaCompany) => (
        <>
            <p>{momentaCompany.line1}</p>
            <p>{momentaCompany.line2}</p>
            <p>{momentaCompany.city}</p>
            <p>{momentaCompany.postcode}</p>
        </>
    );

    if (data && data.version !== "1") {
        throw new Error(`Unexpected model version ${data.version}`);
    }

    const invoiceDate = invoicePeriod.invoiceDate && invoicePeriod.invoiceDate.format("L") || data.invoiceEndDate && data.invoiceEndDate.format("L");

    return (
        <>
            <Grid className="invoices">
                <Grid.Row>
                    <Grid.Column width={8}>
                        <p>Invoice Number: {getInvoicePeriodNumberFormatted(data && data.invoiceNumber)}</p>
                        <p>Invoice Date: {invoiceDate}</p>
                        <p>Invoice Period: {`${data.invoiceStartDate.format("ll")} - ${data.invoiceEndDate.format("ll")}`}</p>
                    </Grid.Column>

                    {data.company && data.company.vatDetails &&
                        <Grid.Column width={8} textAlign="right">
                            <p>{data.associate.forename} {data.associate.surname}</p>
                            <p>{data.company.name}</p>
                            {data.company.address && getAssociateCompanyAddress(data.company)}
                        </Grid.Column>
                    }
                </Grid.Row>

                <Grid.Row>
                    {data.company &&
                        <Grid.Column textAlign="right">
                            <p><strong>Company Registration No:</strong> {data.company.registrationNumber}</p>
                            {invoicePeriod.vatRegistered &&
                                <p><strong>Vat Registration No:</strong> {data.company.vatDetails.vatNumber}</p>
                            }
                        </Grid.Column>
                    }
                </Grid.Row>

                <Grid.Row>
                    {data.momentaCompany && <Grid.Column>
                        <p>{data.momentaCompany.name}</p>
                        {getMomentaCompanyAddress(data.momentaCompany)}
                    </Grid.Column>}
                </Grid.Row>
            </Grid>

            <Table striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan={3}>
                            Consultancy services provided on {data.clientName}, {data.projectName}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                {invoicePeriod.total !== undefined && data.invoiceStartDate &&
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell colSpan={2}>
                                Service Fees
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                <Currency value={invoicePeriod.allServicesFee} />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell colSpan={2}>
                                Expense Fees
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                <Currency value={invoicePeriod.allExpenseFee} />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell colSpan={2}>
                                Bonus Fees
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                <Currency value={invoicePeriod.allBonusFee} />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell colSpan={2}>
                                Adjustment Total
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                <Currency value={data.adjustmentTotal} />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell colSpan={3} />
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell colSpan={2}>
                                Sub-total
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                <Currency value={invoicePeriod.subTotal} />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell colSpan={2}>
                                VAT * @ 20%
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                <Currency value={invoicePeriod.vat} />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell colSpan={2}>
                                Total
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                <Currency value={invoicePeriod.total} />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                }
            </Table>

            <p>*The VAT shown is your output tax due to HMRC</p>
            <p><strong>Important VAT Note</strong></p>
            <p>It is your responsibility to inform Momenta if your company registers or de-registers for VAT, by emailing
                <a className="momenta-team-link" href="mailto:invoices@momentagroup.com"> invoices@momentagroup.com</a>
            </p>

            <InvoiceNote note={note} canEdit={canEdit} save={save} invoicePeriodId={invoicePeriodId} />
        </>
    );
};
