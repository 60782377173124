import { connect } from "react-redux";
import { InvoicePeriod, InvoicesUnconnected } from "@momenta/common/invoicePeriods";

import { isRequestActive } from "redux-request-loading/dist/selectors";

import { AppState } from "../model";

import { associateInvoicePeriodsSelector, associateNameSelector } from "./selectors";

interface InvoicesProps {
    invoicePeriods: InvoicePeriod[];
    total: number;
    basePath: string;
    associateName: string;
    loading: boolean;
}

const mapStateToProps = (state: AppState): InvoicesProps => ({
    invoicePeriods: associateInvoicePeriodsSelector(state) || [],
    total: state.pagination.total,
    basePath: state.router.pathname,
    associateName: associateNameSelector(state),
    loading: isRequestActive(state, "loadInvoicePeriods")
});

export const Invoices = connect(mapStateToProps)(InvoicesUnconnected);
