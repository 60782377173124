import { Pagination, PaginationState, totalSelector } from "@momenta/common/pagination";
import * as React from "react";
import { Button, Grid, Form, Menu } from "semantic-ui-react";
import { InvoicePeriod } from "@momenta/common/invoicePeriods";
import { connect } from "react-redux";
import { Query, push, Link } from "redux-little-router";
import LoadingWrapper from "@momenta/common/LoadingWrapper";
import { isRequestActive, LoadingState } from "redux-request-loading";
import { toast } from "@momenta/common/toasts";
import { DropDownSortMenu } from "@momenta/common/DropDownSortMenu";
import { ProcessInvoiceModel } from "@momenta/common/invoicePeriods/model";
import { Authorize } from "reauthorize";

import { UserRoles } from "../auth/model";

import { processInvoicePeriod } from "./actions";
import { getInvoicePeriodFilter } from "./getInvoicePeriodFilter";
import { basePathSelector } from "./selectors";
import { InvoiceTableFilter } from "./InvoiceTableFilter";
import { AppState } from "./model";
import { InvoicePeriodsTable } from "./InvoicePeriodTable";

export interface InvoicePeriodDetailsProps {
    orderBy: (order: any) => void;
}

export interface InvoicePeriodsStateProps {
    invoicePeriods: InvoicePeriod[];
    total: number;
    basePath: string;
    loading: boolean;
    query: Query;
}

export interface InvoiceTableDispatchProps {
    onSortBy: (query: any, orderBy: any) => void;
    handleInvoiceProcessing: (invoices: ProcessInvoiceModel[]) => void;
}

export const InvoicePeriodDetailsUnconnected: React.FC<InvoicePeriodDetailsProps
    & InvoicePeriodsStateProps & InvoiceTableDispatchProps> = ({
        orderBy,
        invoicePeriods,
        total,
        query,
        basePath,
        loading,
        handleInvoiceProcessing
    }) => {

        const { associateName, invoiceNumber, paymentDueDate, processed } = query;
        const invoiceFilter = getInvoicePeriodFilter(associateName, invoiceNumber, paymentDueDate, processed === "true");

        const exportLink = invoiceFilter !== undefined
            ? `/api/invoiceperiod/download?$filter=${invoiceFilter}`
            : "/api/invoiceperiod/download";

        const exportInvoices = () => {
            toast.info("Exporting invoices, please wait a few moments before trying again");
        };

        const sortChange = (order: any) => {
            clearInvoicesToProcess();
            orderBy(order);
        };

        const sortItems = [
            { text: "Invoice Number (Newest)", value: "Id Desc" },
            { text: "Invoice Number (Oldest)", value: "Id Asc" },
            { text: "Payment Due Date (Newest)", value: "PaymentDueDate Desc" },
            { text: "Payment Due Date (Oldest)", value: "PaymentDueDate Asc" },
            { text: "Invoice Date (Newest)", value: "InvoiceDate Desc" },
            { text: "Invoice Date (Oldest)", value: "InvoiceDate Asc" }
        ];

        const [invoicesToProcess, setInvoicesToProcess] = React.useState<ProcessInvoiceModel[]>();

        const updateInvoicesToProcess = (id: number, value: boolean) => {
            const newInvoicesToProcess = invoicesToProcess.filter(i => i.id !== id);

            if (invoicesToProcess.find(i => i.id === id)) {
                setInvoicesToProcess([...newInvoicesToProcess]);
                return;
            }

            const change: ProcessInvoiceModel = { id, processed: value };
            setInvoicesToProcess([...newInvoicesToProcess, change]);
        };

        const clearInvoicesToProcess = () => {
            setInvoicesToProcess([]);
        };

        const processInvoices = () => {
            loading = true;
            handleInvoiceProcessing(invoicesToProcess);
            clearInvoicesToProcess();
            loading = false;
        };

        return (
            <>
                <LoadingWrapper loading={loading}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={13}>
                                <h1>Umbrella Company Portal</h1>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Button
                                    loading={loading}
                                    primary
                                    content="Export all invoices"
                                    icon="download"
                                    href={exportLink}
                                    as="a"
                                    onClick={exportInvoices} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Authorize authorize={UserRoles.ContractApprover}>
                                <Grid.Column width={2}>
                                    <Menu compact>
                                        <Menu.Item
                                            name='Contracts'
                                            as={Link}
                                            href={"/contracts"}
                                        >
                                            Contracts
                                        </Menu.Item>
                                    </Menu>
                                </Grid.Column>
                            </Authorize>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Grid verticalAlign="top">
                                    <Grid.Row>
                                        <Grid.Column width={2}>
                                            <Form>
                                                <Form.Field>
                                                    <label>
                                                        Order Invoices
                                                    </label>
                                                    <DropDownSortMenu sortChange={sortChange} sortItems={sortItems} />
                                                </Form.Field>
                                            </Form>
                                        </Grid.Column>
                                        <InvoiceTableFilter clearInvoicesToProcess={clearInvoicesToProcess} />
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                        {invoicesToProcess && invoicesToProcess.length > 0 &&
                            <Grid.Row>
                                <Grid.Column>
                                    <Grid verticalAlign="top">
                                        <Grid.Row>
                                            <Grid.Column width={5} floated="right">
                                                <Button floated="right" color="pink" content="Update Invoices" onClick={processInvoices} icon="check" />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        }
                        {invoicePeriods &&
                            <InvoicePeriodsTable
                                invoicePeriods={invoicePeriods}
                                basePath={basePath}
                                onProcessedChanged={updateInvoicesToProcess}
                            />
                        }
                    </Grid>

                    <Pagination total={total} />
                </LoadingWrapper >
            </>
        );
    };

const mapStateToProps = (state: AppState & PaginationState & LoadingState): InvoicePeriodsStateProps => ({
    invoicePeriods: state.invoicePeriods || [],
    total: totalSelector(state),
    basePath: basePathSelector(state),
    loading: isRequestActive(state, "loadInvoicePeriods"),
    query: state.router.query
});

const mapDispatchToProps = (dispatch: any): InvoiceTableDispatchProps => ({
    onSortBy: (query: any, orderBy: any) => {
        dispatch(push({ query: { ...query, orderBy }, pathname: undefined }));
    },
    handleInvoiceProcessing(invoices: ProcessInvoiceModel[]) {
        dispatch(processInvoicePeriod(invoices));
    }
});

const mergeProps = (propsFromState: InvoicePeriodsStateProps, propsFromDispatch: InvoiceTableDispatchProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    orderBy: (order: any) => propsFromDispatch.onSortBy(propsFromState.query, order)
});

export const InvoicePeriodDetails = connect(mapStateToProps, mapDispatchToProps, mergeProps)(InvoicePeriodDetailsUnconnected);
