import { ConfigTypeName, HierarchicalConfiguration } from "@momenta/common/hierarchicalConfiguration";

import * as actions from "./actiontypes";
import { HierarchicalConfigurationAction } from "./actions";

export function createReducer<T extends HierarchicalConfiguration>(type: ConfigTypeName) {

    return function all(state: T[] = [], action: HierarchicalConfigurationAction<T>): T[] {
        if (action.configType !== type) {
            return state;
        }

        switch (action.type) {
            case actions.LOAD_HIERARCHICAL_CONFIGURATION_SUCCESS:
                return action.payload.map(c => ({ ...(c as any) }));
            default:
                return state;
        }
    };
}
