import * as React from "react";
import { Table } from "semantic-ui-react";

import { Contract } from "../model";

interface ContractTableRowProps {
    key: string;
    contract: Contract;
}

export const ContractTableRow: React.FC<ContractTableRowProps> = ({ contract, key }) => {
    return (
        <Table.Row key={key}>
            <Table.Cell content={contract.momentaAssociateID}></Table.Cell>
            <Table.Cell content={contract.associateName}></Table.Cell>
            <Table.Cell content={contract.client}></Table.Cell>
            <Table.Cell content={contract.project}></Table.Cell>
            <Table.Cell content={contract.startDate}></Table.Cell>
            <Table.Cell content={"View"}></Table.Cell>
            <Table.Cell content={contract.contractStatus}></Table.Cell>
        </Table.Row>
    );
};
