import * as React from "react";
import { Button, DropdownItemProps, Grid, Select } from "semantic-ui-react";
import { paginationCalculator } from "pagination-calculator";

export interface PaginationProps {
    total: number;
    page: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    basic?: boolean;
    onPageSizeChange?: (pageSize: number, page: number) => void;
    showAllOption?: boolean;
    maxPageSize?: number;
    pageSizes?: number[];
    loading?: boolean;
}

export const PaginationComponent: React.SFC<PaginationProps> = ({
    page,
    pageSize,
    total,
    onPageChange,
    basic,
    onPageSizeChange,
    showAllOption,
    maxPageSize,
    pageSizes,
    loading
}) => {

    const { pages, previous, next, pageCount, showingStart, showingEnd } = paginationCalculator({
        total,
        current: page,
        pageSize,
        pageLimit: 10
    });

    const max = maxPageSize || 500;

    const showAll = total > max ? max : total;

    const onChange = (e: any, { value }: { value: string }) => {
        const newPageSize = +value;
        let newPage = page;

        if (page > 1) {
            const position = ((page - 1) * pageSize) + 1; // get the position of the first item on the current page
            newPage = Math.ceil(position / newPageSize);
        }

        onPageSizeChange(newPageSize, newPage);
    };

    const options: DropdownItemProps[] = (pageSizes || []).map(size => ({
        text: `Show ${size}`,
        value: size
    }));

    if (options.length > 0) {

        if (showAllOption) {
            options.push({
                text: `Show ${showAll}`,
                value: showAll
            });
        }
    }

    const getButtonProps = (pageNumber: (number | "...")) => {
        if (pageNumber === "...") {
            return {};
        }

        return {
            onClick: () => onPageChange(pageNumber)
        };
    };

    const isLoading = loading !== undefined && loading;

    const goToPreviousPage = () => { if (previous !== false) { onPageChange(previous); } };
    const goToNextPage = () => { if (next !== false) { onPageChange(next); } };
    return total > 0 && (
        <Grid>
            <Grid.Row>

                <Grid.Column width={3}>
                    {previous !== false && <Button
                        content="Previous"
                        icon="left arrow"
                        labelPosition="left"
                        floated="left"
                        basic={basic}
                        size="mini"
                        onClick={goToPreviousPage}
                        disabled={isLoading}
                    />}
                </Grid.Column>

                <Grid.Column width={10} as={Button.Group} textAlign="center" size="mini">
                    {pages.length > 1 && pages.map((p, k) => (
                        <Button
                            active={page === p}
                            key={k}
                            content={p}
                            basic={basic}
                            disabled={p === "..." || isLoading}
                            {...getButtonProps(p)}
                        />
                    ))}
                </Grid.Column>

                <Grid.Column width={3}>
                    {next !== false && <Button
                        content="Next"
                        icon="right arrow"
                        labelPosition="right"
                        floated="right"
                        basic={basic}
                        size="mini"
                        onClick={goToNextPage}
                        disabled={isLoading}
                    />}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={10} floated="left">
                    <p>Showing {showingStart} to {showingEnd} of {total}</p>
                </Grid.Column>

                <Grid.Column width={6} floated="right">
                    {options.length > 0 && onPageSizeChange && (pageCount > 1 || pageSize === total) && <Select
                        value={pageSize}
                        onChange={onChange}
                        options={options}
                        fluid
                        search
                        selection
                        disabled={isLoading}
                    />}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
