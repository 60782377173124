import { routes as invoicePeriods } from "./invoicePeriods";
import { routes as contracts } from "./contracts/routes";

export const routes: any = {
    "/": {
        title: "Home",
        authorize: false
    },
    "/forbidden": {
        authorize: false
    },
    ...contracts,
    ...invoicePeriods
};
